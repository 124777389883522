import NoAnnotationsIcon from "@assets/icons/new/no_annotations.svg?react";
import { TeamGroupTable } from "@components/table/team-group/team-group-table";
import { TeamGroupDetails } from "@pages/members/team-groups/team-groups-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import {
  hasFetchedTeamGroupsSelector,
  teamGroupsSelector,
} from "@store/team/team-selector";
import { fetchTeam } from "@store/team/team-thunk";
import { useEffect, useMemo } from "react";
import { TeamGroupHeaders } from "@components/table/team-group/team-groups-table-utils";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { searchSelector } from "@store/ui/ui-selector";
import { useDebounce } from "@utils/time-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import FailedIcon from "@assets/icons/failed_32px.svg?react";

/**
 * The component that will render the kpi details and the table for the user groups
 */
export function TeamGroups(): JSX.Element {
  const dispatch = useAppDispatch();
  const mockedTeamGroups = useAppSelector(teamGroupsSelector);
  const hasFetchedTeamGroups = useAppSelector(hasFetchedTeamGroupsSelector);
  const { searchText } = useAppSelector(searchSelector);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    dispatch(fetchTeam());
  }, [dispatch]);

  const shouldShowEmptyPage = useMemo(() => {
    if (searchText) {
      return !mockedTeamGroups.length;
    }

    return (
      hasFetchedTeamGroups === FetchingStatus.succeeded &&
      mockedTeamGroups.length === 0
    );
  }, [hasFetchedTeamGroups, mockedTeamGroups.length, searchText]);

  /** Debounce interval in milliseconds */
  const DEBOUNCE_TIME = 300;

  // Uses useDebounce hook to react to changes on the search input text.
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_TIME);

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        name: SearchbarEvents.searchForTeamGroup,
      });
    }
  }, [debouncedSearchText, trackEvent]);

  return shouldShowEmptyPage ? (
    <EmptyPage
      title={
        hasFetchedTeamGroups === FetchingStatus.rejected
          ? "Error"
          : "No user groups"
      }
      subtitle={
        hasFetchedTeamGroups === FetchingStatus.rejected
          ? "Failed to fetch the user groups. Please reload the page and try again."
          : "There aren't user groups to show."
      }
      icon={
        hasFetchedTeamGroups === FetchingStatus.rejected
          ? FailedIcon
          : NoAnnotationsIcon
      }
    />
  ) : (
    <>
      <TeamGroupDetails />
      <TeamGroupTable
        teamGroups={mockedTeamGroups}
        isLoading={hasFetchedTeamGroups === FetchingStatus.pending}
        requiredColumns={[
          TeamGroupHeaders.group,
          TeamGroupHeaders.users,
          TeamGroupHeaders.creationDate,
          TeamGroupHeaders.options,
        ]}
      />
    </>
  );
}
