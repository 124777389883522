import { createSelector } from "@reduxjs/toolkit";
import { runtimeConfig } from "@src/runtime-config";
import { RuntimeUtils } from "@stellar/web-core";
import { RootState } from "@store/store-helper";
import { UiState } from "@store/ui/ui-slice";

/**
 * Returns the format that the data is displayed
 */
export const dataViewModeSelector: (
  state: RootState
) => UiState["dataViewMode"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.ui.dataViewMode;
  }
);

/**
 * Returns the sidebar property
 */
export const sidebarSelector: (state: RootState) => UiState["sidebar"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.ui.sidebar;
    }
  );

/**
 * Returns the search property
 */
export const searchSelector: (state: RootState) => UiState["search"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.ui.search;
    }
  );

/**
 * Returns whether the beta testing is enabled
 */
export const isBetaTestingEnabledSelector: (
  state: RootState
) => UiState["isBetaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.ui.isBetaTestingEnabled;
  }
);

/**
 * Returns whether the alpha testing is enabled
 */
export const isAlphaTestingEnabledSelector: (
  state: RootState
) => UiState["isAlphaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.ui.isAlphaTestingEnabled;
  }
);

/**
 * Returns whether the beta testing is enabled or the environments is not production.
 */
export const isBetaTestingEnabledOrNotProdSelector: (
  state: RootState
) => UiState["isBetaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const isProd = RuntimeUtils.isProductionEnvironment(runtimeConfig.appEnv);
    return state.ui.isBetaTestingEnabled || !isProd;
  }
);
