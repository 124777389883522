/** All the possible headers for team groups table. */
export enum TeamGroupHeaders {
  /** Team group */
  group = "Group",

  /** Team group users */
  users = "Users",

  /** Team group creation date */
  creationDate = "Creation date",

  /** Any other possible options */
  options = "Options",
}
