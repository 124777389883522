import { AppRoutes } from "@router/router-helper";
import { useMatch } from "react-router-dom";

interface UseRouteMatch {
  /** Whether the route matches the project overview or not */
  isProjectsRoute: boolean;

  /** Whether the route matches the project details or not */
  isProjectDetailsRoute: boolean;

  /** Whether the route matches the company team or not */
  isMembersRoute: boolean;

  /** Whether the route matches the company group or not */
  isGroupsRoute: boolean;

  /** Whether the route matches the workspace selection or not */
  isWorkspaceSelectionRoute: boolean;

  /** Whether the route matches the team groups selection or not */
  isTeamGroupsRoute: boolean;
}

/**
 * A hook that matches the current route (pathname) with the provided paths
 * and return an object of which paths are matched and which are not
 */
export function useRouteMatch(): UseRouteMatch {
  const matchProjectRoute = useMatch(AppRoutes.ProjectsRoute);
  const matchProjectDetailsRoute = useMatch(AppRoutes.ProjectDetailsRoute);
  const matchTeamRoute = useMatch(AppRoutes.MembersRoute);
  const matchGroupsRoute = useMatch(AppRoutes.GroupsRoute);
  const matchWorkspaceSelectionRoute = useMatch(
    AppRoutes.WorkspaceSelectionRoute
  );
  const matchTeamGroups = useMatch(AppRoutes.TeamRoute);

  return {
    isProjectsRoute: Boolean(matchProjectRoute),
    isProjectDetailsRoute: Boolean(matchProjectDetailsRoute),
    isMembersRoute: Boolean(matchTeamRoute),
    isGroupsRoute: Boolean(matchGroupsRoute),
    isWorkspaceSelectionRoute: Boolean(matchWorkspaceSelectionRoute),
    isTeamGroupsRoute: Boolean(matchTeamGroups),
  };
}
