// app-config.ts
import { RuntimeConfig } from "@src/runtime-config";
import { getNumberedEnv, isHostNumberedEnv } from "@utils/url-utils";

export const appVersion: string = "1.0.0-feature-ST-2240-part-3-display-mocked-user-groups-table.9";
export const appTimestamp: string = "2024-09-18T15:22:06.000Z";
export const commitId: string = "2079976ab6b0aa0425ec779fb5f8e6186b440169";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/commit/2079976ab6b0aa0425ec779fb5f8e6186b440169?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "27849";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/pullrequest/27849";
export const jiraTicketId: string = "ST-2240";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-2240";

const sentryDSNKey =
  "https://c9b011b3368bc41e188fcbae7fb7a872@o381590.ingest.sentry.io/4505674738499584";
const localizeKey = "iKNI0OJMw3RLe";
const muiProKey =
  // eslint-disable-next-line max-len -- this is a license key
  "3d69a08fc3c9d75a1ff22bebf8fcfd23Tz05NjEyMyxFPTE3NTUyNDI3NzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==";

const isNumberedEnv = isHostNumberedEnv(window.location.host);
const numberedEnv = isNumberedEnv ? getNumberedEnv(window.location.host) : null;

const developmentEu = {
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.eu",
    sphereViewerUrl: "https://viewer.dev.holobuilder.eu",
    dashboard20Api: "https://sphere.dev.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.dev.holobuilder.eu",
    progressApiUrl: "https://progress.api.dev.holobuilder.eu",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.dev.holobuilder.eu"
        : "https://entry.dev.holobuilder.eu",
    cookieManagerUrl: "",
    cookieManagerPreflyUrl: "",
    powerBIBaseUrl: "https://hb-powerbi-func-eu-dev.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.dev.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.dev.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.dev.holobuilder.eu",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.dev.farosphere.com",
      clientId: "wSsYe8Ye5nQ0jfTxs0Urxrqy9evj6W2u",
    },
  },
  appEnv: "eu-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const developmentCom = {
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.com",
    sphereViewerUrl: "https://viewer.dev.holobuilder.com",
    dashboard20Api: "https://sphere.dev.holobuilder.com/api",
    hbWebEditorUrl: "https://app.dev.holobuilder.com",
    progressApiUrl: "https://progress.api.dev.holobuilder.com",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.dev.holobuilder.com"
        : "https://entry.dev.holobuilder.com",
    cookieManagerUrl:
      "https://cookies.dev.holobuilder.com/cookie-manager/manager/latest",
    cookieManagerPreflyUrl:
      "https://cookies.dev.holobuilder.com/cookie-manager/prefly/latest",
    powerBIBaseUrl: "https://hb-powerbi-func-com-dev.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.dev.holobuilder.com",
    hbDashboardUrl: "https://dashboard.dev.holobuilder.com",
    registrationApiUrl: "https://cloud-reg.api.dev.holobuilder.com",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: {
      auth: "KzafiVZpCW_NESexpLNi4A",
      preview: "env-323",
      id: "GTM-PGZC4L",
    },
    auth0: {
      domain: "login.eu.dev.farosphere.com",
      clientId: "wSsYe8Ye5nQ0jfTxs0Urxrqy9evj6W2u",
    },
  },
  appEnv: "com-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingEu = {
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.eu",
    sphereViewerUrl: "https://viewer.staging.holobuilder.eu",
    dashboard20Api: "https://sphere.staging.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.staging.holobuilder.eu",
    progressApiUrl: "https://progress.api.staging.holobuilder.eu",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.staging.holobuilder.eu"
        : "https://entry.staging.holobuilder.eu",
    cookieManagerUrl: "",
    cookieManagerPreflyUrl: "",
    powerBIBaseUrl: "https://hb-powerbi-func-eu-staging.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.staging.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.staging.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.staging.holobuilder.eu",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.stg.farosphere.com",
      clientId: "iRaekFL1jv86gIakQI31pbVMwGLEwB5T",
    },
  },
  appEnv: "eu-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingCom = {
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.com",
    sphereViewerUrl: "https://viewer.staging.holobuilder.com",
    dashboard20Api: "https://sphere.staging.holobuilder.com/api",
    hbWebEditorUrl: "https://app.staging.holobuilder.com",
    progressApiUrl: "https://progress.api.staging.holobuilder.com",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.staging.holobuilder.com"
        : "https://entry.staging.holobuilder.com",
    cookieManagerUrl:
      "https://cookies.staging.holobuilder.com/cookie-manager/manager/latest",
    cookieManagerPreflyUrl:
      "https://cookies.staging.holobuilder.com/cookie-manager/prefly/latest",
    powerBIBaseUrl: "https://hb-powerbi-func-com-staging.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.staging.holobuilder.com",
    hbDashboardUrl: "https://dashboard.staging.holobuilder.com",
    registrationApiUrl: "https://cloud-reg.api.staging.holobuilder.com",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: {
      auth: "5EgnC5iiXCgkb63BXWkCtw",
      preview: "env-324",
      id: "GTM-PGZC4L",
    },
    auth0: {
      domain: "login.eu.stg.farosphere.com",
      clientId: "iRaekFL1jv86gIakQI31pbVMwGLEwB5T",
    },
  },
  appEnv: "com-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const prodEu = {
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.eu",
    sphereViewerUrl: "https://viewer.holobuilder.eu",
    dashboard20Api: "https://sphere.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.holobuilder.eu",
    progressApiUrl: "https://progress.api.holobuilder.eu",
    sphereEntryPageUrl: "https://entry.holobuilder.eu",
    cookieManagerUrl: "",
    cookieManagerPreflyUrl: "",
    powerBIBaseUrl: "https://hb-powerbi-func-eu.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.holobuilder.eu",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.farosphere.com",
      clientId: "VIgbt2dDKTFhsj2as6umqhKylJ01psTg",
    },
  },
  appEnv: "eu-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodCom = {
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.com",
    sphereViewerUrl: "https://viewer.holobuilder.com",
    dashboard20Api: "https://sphere.holobuilder.com/api",
    hbWebEditorUrl: "https://app.holobuilder.com",
    progressApiUrl: "https://progress.api.holobuilder.com",
    sphereEntryPageUrl: "https://entry.holobuilder.com",
    cookieManagerUrl:
      "https://cookies.holobuilder.com/cookie-manager/manager/latest",
    cookieManagerPreflyUrl:
      "https://cookies.holobuilder.com/cookie-manager/prefly/latest",
    powerBIBaseUrl: "https://hb-powerbi-func-com.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.holobuilder.com",
    hbDashboardUrl: "https://dashboard.holobuilder.com",
    registrationApiUrl: "https://cloud-reg.api.holobuilder.com",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.farosphere.com",
      clientId: "VIgbt2dDKTFhsj2as6umqhKylJ01psTg",
    },
  },
  appEnv: "com-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentEuLocal = {
  ...developmentEu,
  features: {
    ...developmentEu.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentComLocal = {
  ...developmentCom,
  features: {
    ...developmentCom.features,
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingEuLocal = {
  ...stagingEu,
  features: {
    ...stagingEu.features,
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingComLocal = {
  ...stagingCom,
  features: {
    ...stagingCom.features,
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodEuLocal = {
  ...prodEu,
  features: {
    ...prodEu.features,
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodComLocal = {
  ...prodCom,
  features: {
    ...prodCom.features,
    sentryDSNKey: "",
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

export const runtimeConfigObject: { [envName: string]: RuntimeConfig } = {
  developmentEu,
  developmentCom,
  stagingEu,
  stagingCom,
  prodEu,
  prodCom,
  developmentEuLocal,
  developmentComLocal,
  stagingEuLocal,
  stagingComLocal,
  prodEuLocal,
  prodComLocal,
};
