import { useRouteMatch } from "@hooks/route-match/use-route-match";

/**
 * A hook that determines the searchbar visibility based on the route
 */
export function useShouldShowSearchbar(): boolean {
  const routes = useRouteMatch();

  return Boolean(
    routes.isProjectsRoute ||
      routes.isMembersRoute ||
      routes.isGroupsRoute ||
      routes.isWorkspaceSelectionRoute ||
      routes.isTeamGroupsRoute
  );
}
