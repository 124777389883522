import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { TeamGroup } from "@store/team/team-types";
import { teamGroupAdapter } from "@store/team/team-slice";

/** Return the list of team groups. */
export const teamGroupsSelector: (state: RootState) => TeamGroup[] =
  createSelector(
    (state: RootState) => ({
      teamGroups: teamGroupAdapter.getSelectors().selectAll(state.teams),
      searchText: state.ui.search.searchText.toLowerCase(),
    }),
    ({ teamGroups, searchText }) => {
      return teamGroups.filter((teamGroup) =>
        teamGroup.name.toLowerCase().includes(searchText)
      );
    }
  );

export const hasFetchedTeamGroupsSelector: (
  state: RootState
) => FetchingStatus = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.teams.status;
  }
);
