import { createAsyncThunk } from "@reduxjs/toolkit";
import { TeamGroup } from "@store/team/team-types";

const mockedTeamGroups: TeamGroup[] = Array.from({ length: 70 }, (_, index) => {
  const showDescription = 10;

  const description =
    (index + 1) % showDescription === 0
      ? `Description for Group ${index + 1}`
      : undefined;

  let numMembers = 3;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (index % 3 === 0) {
    numMembers = 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (index % 5 === 0) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    numMembers = 5;
  }

  return {
    id: (index + 1).toString(),
    name: `Group ${index + 1}`,
    description,
    createdAt: "2023-09-17T12:00:00Z",
    sampleMembers: Array.from({ length: numMembers }, (_, memberIndex) => ({
      userResponse: {
        id: `user${index + 1}-${memberIndex + 1}`,
        identity: (index + 1).toString(),
        lastName: `LastName${index + 1}-${memberIndex + 1}`,
        firstName: `FirstName${index + 1}-${memberIndex + 1}`,
        email: `user${index + 1}-${memberIndex + 1}@example.com`,
      },
      createdAt: "2023-09-17T12:00:00Z",
    })),
  };
});

export const fetchTeam = createAsyncThunk<TeamGroup[]>(
  "teams/fetchTeam",
  async () => {
    return new Promise<TeamGroup[]>((resolve) =>
      setTimeout(() => resolve(mockedTeamGroups), 0)
    );
  }
);
