import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TeamGroupHeaders } from "@components/table/team-group/team-groups-table-utils";
import { TeamGroup } from "@store/team/team-types";
import { FormatDate } from "@hooks/use-date-time";
import { TeamGroupName } from "@components/table/team-group/team-group-name";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { DateTime } from "luxon";

interface Props {
  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };

  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;
}

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Return all the possible columns for Teams table */
export function getTeamGroupsColumns({
  options,
  formatDate,
}: Props): Record<TeamGroupHeaders, GridColDef> {
  const flexSetting = options.isScreenLgOrLarger ? 1 : undefined;

  return {
    [TeamGroupHeaders.group]: {
      field: TeamGroupHeaders.group,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 450,
      renderCell: (params: GridRenderCellParams<{ entity: TeamGroup }>) => {
        return <TeamGroupName groupName={params.row.entity} />;
      },
    },
    [TeamGroupHeaders.users]: {
      field: TeamGroupHeaders.users,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 450,
      renderCell: (_: GridRenderCellParams<{ entity: TeamGroup }>) => {
        return "";
      },
    },

    [TeamGroupHeaders.creationDate]: {
      field: TeamGroupHeaders.creationDate,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 450,
      renderCell: (params: GridRenderCellParams<{ entity: TeamGroup }>) => {
        return (
          <var>
            {formatDate(params.row.entity.createdAt, DateTime.DATETIME_MED)}
          </var>
        );
      },
    },

    [TeamGroupHeaders.options]: {
      field: TeamGroupHeaders.options,
      type: "actions",
      align: "right",
      maxWidth: 90,
      renderCell: (_: GridRenderCellParams<{ entity: TeamGroup }>) => {
        return (
          <SphereTooltip
            title={"Delete group"}
            boxProps={{
              className: SHOW_ON_HOVER_CLASS,
              sx: {
                visibility: "hidden",
              },
            }}
          >
            <FaroIconButton component={DeleteIcon} iconSize="18px" />
          </SphereTooltip>
        );
      },
    },
  };
}
